<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: orgPath }"
          >{{breadcrumbName}}</el-breadcrumb-item
        >
        <el-breadcrumb-item>凭证归集</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <p>
          九部委最新文件种明确规定的九种数字凭证的归集。用户无需再登录多种平台或系统，只需登录数电通平台即可完成一站式凭证获取。
          随着《关于公布电子凭证会计数据标准的通知》的出台，规范了“结构化数据文件+版式文件封装+电子签名”的电子凭证标准，有效保障了数据来源的合法性、真实性以及数据安全性，确保了电子凭证的法律效力，标志着真正“无纸化”时代的到来。
          未来，随着原始数据对原始凭证的完全替代，经济活动流转的媒介不再是纸张，而是永不下线的数据。新要素、新媒介，将势必会带来数字经济下全社会诚信体系的变革与重塑。
          2023年5月18日，财政部会计司发布了《关于公布电子凭证会计数据标准的通知》，制定了9类电子凭证会计数据标准（试行版）
          。从企业视角看，本次电子凭证的会计标准覆盖了税企、政企、企企、银企经营交互活动中最高频获取的外部纸质单据，也是财务核算工作中最常见的原始凭据。
          我国电子凭证的会计标准内容拓展与本年深化试点工作对于推动财务工作无纸化、推动经济社会发展绿色低碳化、形成全社会互连互信机制具有重要意义。

          <span>数据获取环节</span>
          采用电子凭证数据标准形成的数据记录具备作为原始凭证入账的效力，有效解决了单据造假问题，更不需要手工录入数据，业务的真实性、数据的准确性与完整性得到保障。
          遵从会计数据标准建立的内外部系统接口，将具有标准化的特点，不再受限于各个独立系统现有的数据描述方式。这种变化将有效降低系统接口管理的复杂度、提高接口建设的效率，并让数据交互功能具备模块化、通用化的条件，从而形成普遍性服务，让不具备独立设计、开发条件的中小型企业也可以利用这些通用模块，享受到数据直连的便利。

          <span>业务处理环节</span>
          以在线数据作为处理依据，彻底转变财务工作中的纸质依赖习惯，有效解决审核时线上与线下信息反复对比确认的检查工作，确保数据校验一次到位，提高流程整体的效率。

          <span>信息存档环节</span>
          遵循会计数据标准形成的电子凭证可直接以电子文档形式保存，大幅提高了存档效率、降低了档案管理的开支，信息存档过程也就是数据资产沉淀过程，让档案数据也成为公司数据资产的组成部分。
        </p>
      </div>
    </div>

    <div class="btn" @click="subInfo">立即体验</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      org: "",
      orgPath: "",
      breadcrumbName: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    subInfo() {
      this.$router.push({
        path: "/info",
        query: { org: "sz" },
      });
    },
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "home") {
      this.orgPath = "/";
      this.breadcrumbName = "首页";
    } else {
      this.orgPath = "/finance_1";
      this.breadcrumbName = "代账报税服务";
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
}

.box p:first-child {
  padding-top: 0;
}

.box p span {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}
</style>
